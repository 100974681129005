<template>
    <div class="c-order-row">
        <mercur-card class="pt-3" style="padding-bottom: 0 !important;" v-if="params.data">
            <merchant-data-table class="c-standard-table--row-separated" style="min-height: auto;" :options="orderOptions" :items="orderData" :isNotApplicationBound="true"></merchant-data-table>
            <hr>

            <ag-grid-vue v-if="rowData" class="ag-theme-balham c-order-row__orderline-table"
                         style="width: 100%; height: auto; min-height: auto;"
                         :columnDefs="columnDefs"
                         :rowData="rowData"
                         :cacheBlockSize="5"
                         :paginationPageSize="5"
                         :pagination="true"
                         :gridOptions="gridOptions"
                         @grid-ready="onGridReady"
            >
            </ag-grid-vue>
        </mercur-card>
    </div>
</template>

<script>
import Vue from 'vue'
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'

import RouterLinkCell from '@/components/elements/table/RouterLinkCell'
import Currency from '@/components/elements/Currency'
import MerchantDataTable from '@/components/elements/MerchantDataTable'

export default Vue.extend({
    name: 'OrderRow',
    components: { AgGridVue, MerchantDataTable },
    data () {
        return {
            columnDefs: null,
            gridOptions: null,
            orderOptions: {
                columns: {
                    'Order Id': {
                        field: 'orderNumber',
                        valueFormatter: ({ value, data }) => `${value} ${data.quote ? ' (quote)' : ''}`,
                        link: (value, data) => this.routeOrder(data.orderId, data.accountId, data.applicationId),
                    },
                    'Order Date': { field: 'dateCreated' },
                    'Customer': {
                        field: 'billingInfo.address.firstName',
                        valueFormatter: (params) => {
                            return `${params.data.billingInfo.address.firstName} ${params.data.billingInfo.address.lastName}`
                        },
                        link: (value, data) => this.routeCustomer(data.accountId, data.applicationId),
                    },
                    'lastName': { field: 'billingInfo.address.lastName', hide: true },
                    'Company': { field: 'billingInfo.address.companyName' },
                    'Order Status': { field: 'orderStatus' },
                    'Order Total': {
                        field: 'totals.total',
                        cellRendererFramework: Currency,
                        cellRendererParams: (params) => {
                            return {
                                value: params.value,
                                type: this.params.data.currency,
                            }
                        },
                    },
                    'Shop': { field: 'applicationName' },
                    'pspReference': {
                        colId: 'pspReference',
                    },
                    'paymentReference': {
                        colId: 'paymentReference',
                    },
                },
                pagination: false,
                headerHeight: 28,
                customCellClass: 'c-standard-table__cell--dense',
                defaultColDef: { menuTabs: [] },
            },
        }
    },
    methods: {
        onGridReady (params) {
            setTimeout(() => {
                params.api.sizeColumnsToFit()
            }, 100)
        },
        routeOrder (orderId, accountId, applicationId) {
            return {
                name: 'OrderWrapper',
                params: { orderId, accountId, applicationId },
            }
        },
        routeCustomer (id, applicationId) {
            return {
                name: 'CustomerView',
                params: {
                    customerId: id,
                    applicationId: applicationId,
                },
            }
        },
        getOrderLineRoute (params) {
            return {
                name: 'OrderLineView',
                params: {
                    ...this.$route.params,
                    ...params,
                },
            }
        },
    },
    computed: {
        rowData () {
            return this.params.data.orderLines
        },
        orderData () {
            const data = JSON.parse(JSON.stringify(this.params.data))
            data.totalRows = 1
            return [data]
        },
    },

    beforeMount () {
        this.gridOptions = {
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            rowHeight: 44,
            getRowClass: function (params) {
                if (params.node.rowIndex % 2 === 0) {
                    return 'c-order-row__stripped-row'
                }
            },
        }
        this.columnDefs = [
            {
                headerName: 'Order line id',
                field: 'orderLineNumber',
                cellRendererFramework: RouterLinkCell,
                cellRendererParams: (params) => {
                    return {
                        routerlink: {
                            to: this.getOrderLineRoute({
                                accountId: this.params.data.accountId,
                                orderId: params.data.orderId,
                                orderLineId: params.data.orderLineId,
                                applicationId: this.params.data.applicationId,
                            }),
                        },
                    }
                },
            },
            { headerName: 'Product name', field: 'product.productName' },
            { headerName: 'SKU', field: 'product.sku' },
            { headerName: 'Status', field: 'orderLineStatus' },
            { headerName: 'Target Dispatch date', field: 'deliveryInfo.targetDispatchDate' },
            { headerName: 'Target Delivery Date', field: 'deliveryInfo.targetDeliveryDate' },
            { headerName: 'Product price',
                field: 'productPrice',
                cellRendererFramework: Currency,
                cellRendererParams: (params) => {
                    return {
                        value: params.value,
                        type: params.data.product.prices.currencyCode,
                    }
                },
            },
            { headerName: 'Shipping price',
                field: 'shippingPrice',
                cellRendererFramework: Currency,
                cellRendererParams: (params) => {
                    return {
                        value: params.value,
                        type: params.data.product.prices.currencyCode,
                    }
                },
            },
            { headerName: 'Qty', field: 'product.quantity' },
        ]
    },
})
</script>
