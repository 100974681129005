<template>
    <div class="grid-wrapper">
        <div class="c-default-table__pagination">
            <p class="c-default-table__pagination-info">
                Display 10
<!--                <mercur-select v-model="numberOfRows" @selected="changeAmountOfItems" class="c-default-table__pagination-amount">-->
<!--                    <option value="10">10</option>-->
<!--                    <option value="25">25</option>-->
<!--                    <option value="50">50</option>-->
<!--                    <option value="100">100</option>-->
<!--                </mercur-select>-->
                of {{ pagination.totalRows }} items, Page {{ pagination.currentPage }} of {{ pagination.totalPages }}
            </p>
            <mercur-button class="btn c-default-table__pagination-button" @click.native="toPreviousPage"><i class="fas fa-chevron-left"></i></mercur-button>
            <mercur-button class="btn c-default-table__pagination-button" @click.native="toNextPage"><i class="fas fa-chevron-right"></i></mercur-button>
        </div>

        <ag-grid-vue class="ag-theme-balham c-order-table"
                     data-e2e="ordersTableList"
                     style="width: 100%; height: auto;"
                     :columnDefs="columnDefs"
                     :rowModelType="rowModel"
                     :rowData="items"
                     :gridOptions="gridOptions"
                     :getRowHeight="getRowHeight"
                     :isFullWidthCell="isFullWidthCell"
                     :fullWidthCellRenderer="fullWidthCellRenderer"
                     :frameworkComponents="frameworkComponents"
                     :pagination="true"
                     :cacheBlockSize="numberOfRows"
                     :paginationPageSize="numberOfRows"
                     suppressPaginationPanel=true
                     @pagination-changed="onPaginationChanged"
                     @grid-ready="onGridReady"
        >
        </ag-grid-vue>
    </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'
import OrderRow from '@/components/elements/order/Row'
import DelayedRow from '@/components/elements/order/DelayedRow'
import ApiServerSideDataSource from '@/components/utils/ApiServerSideDataSource'
import { mapGetters } from 'vuex'

export default {
    name: 'OrderTable',
    components: {
        AgGridVue,
    },
    props: {
        items: {
            type: Array,
            default: function () {
                return []
            },
        },
        url: {
            default: null,
        },
        presetRowNumber: {
            default: 10,
        },
        isDelayed: {
            type: Boolean,
            default: false,
        },
        params: {
            default: () => {
                return {}
            },
        },
    },
    data () {
        return {
            columnDefs: [],
            gridOptions: null,
            getRowHeight: null,
            isFullWidthCell: null,
            fullWidthCellRenderer: null,
            frameworkComponents: null,
            filterModel: {},
            numberOfRows: this.presetRowNumber,
            pagination: {
                currentPage: 1,
                totalPages: 1,
                totalRows: 1,
            },
        }
    },
    computed: {
        ...mapGetters('loading', ['getPendingJobs']),
        rowModel () {
            if (this.url) {
                return 'serverSide'
            }
            return 'clientSide'
        },
    },
    methods: {
        onGridReady (params) {
            window.addEventListener('resize', () => {
                this.gridApi.sizeColumnsToFit()
            })

            if (this.rowModel === 'clientSide' || (this.getPendingJobs && this.getPendingJobs.includes('/orders/overview'))) {
                return
            }

            this.gridApi.setSortModel([{
                colId: 'dateCreated', sort: 'desc',
            }])

            this.serverSideDatasource = new ApiServerSideDataSource({
                $api: this.$api,
                url: this.url,
                getFilterModel: () => this.filterModel,
                responseHandler: (successCallback, { data }) => {
                    if (data.length === 0) {
                        params.api.showNoRowsOverlay()
                    }
                    const extendedData = data.map((e) => {
                        return {
                            ...e,
                            pspReference: '',
                            paymentReference: '',
                        }
                    })

                    successCallback(extendedData, data[0].totalRows)
                    setTimeout(() => {
                        params.api.sizeColumnsToFit()
                    }, 10)
                },
                errorHandler: (error) => {
                    console.log(error)
                },
            })

            this.onPaginationChanged()
            params.api.setServerSideDatasource(this.serverSideDatasource)
        },
        onPaginationChanged () {
            if (this.gridApi) {
                this.pagination = {
                    currentPage: this.gridApi.paginationGetCurrentPage() + 1,
                    totalPages: this.gridApi.paginationGetTotalPages(),
                    totalRows: this.gridApi.paginationGetRowCount(),
                }
            }
        },
        changeAmountOfItems (value) {
            this.$set(this, 'numberOfRows', Number(value))
            this.refreshGrid()
        },
        toPreviousPage () {
            this.gridApi.paginationGoToPreviousPage()
        },
        toNextPage () {
            this.gridApi.paginationGoToNextPage()
        },
        onModelUpdated (params) {
            params.api.sizeColumnsToFit()
        },
        refreshGrid () {
            this.gridApi.purgeServerSideCache()
        },
        applyFilter (filter) {
            this.gridApi.setFilterModel(filter)
        },
        applySorting (sortModel) {
            this.gridApi.setSortModel(sortModel)
        },
        listen () {
            this.refreshGrid()
            this.gridApi.hideOverlay()
        },
    },
    beforeMount () {
        this.gridOptions = {
            defaultColDef: {
                sortable: true,
                autoHeight: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                    caseSensitive: true,
                },
            },
            headerHeight: 0,
        }
        this.columnDefs = [
            { headerName: '', field: 'orderId', hide: true },
            { headerName: 'Order Number', field: 'orderNumber' },
            { headerName: 'Order Date', field: 'dateCreated' },
            { headerName: 'Date updated', field: 'dateUpdated', hide: true },
            { headerName: 'Customer', field: 'billingInfo.address.firstName' },
            { headerName: 'lastName', field: 'billingInfo.address.lastName', hide: true },
            { headerName: 'Company', field: 'company' },
            { headerName: 'Payment Method', field: 'paymentMethod' },
            { headerName: 'Order Status', field: 'orderStatus' },
            { headerName: 'Order Total', field: 'orderTotal' },
            { headerName: 'Shop', field: 'applicationName' },
            { headerName: 'Order Line Status', colId: 'orderLineStatus', hide: true },
            { headerName: 'Order Line Number', colId: 'orderLineNumber', hide: true },
            { headerName: 'Psp Reference', colId: 'pspReference', hide: true },
            { headerName: 'Payment Reference', colId: 'paymentReference', hide: true },
        ]

        this.getRowHeight = params => {
            if (!params.data) {
                return 50
            }
            if (this.isDelayed && !params.data.orderLines) {
                return 100
            }
            const factor = params.data.orderLines.length < 6 ? params.data.orderLines.length : 5
            return 210 + (factor * 44)
        }
        this.frameworkComponents = { fullWidthCellRenderer: this.isDelayed ? DelayedRow : OrderRow }
        this.isFullWidthCell = rowNode => {
            return rowNode.data
        }
        this.fullWidthCellRenderer = 'fullWidthCellRenderer'
    },
    mounted () {
        this.gridApi = this.gridOptions.api
        this.gridColumnApi = this.gridOptions.columnApi

        this.$bus.$on('shopWasChanged', this.listen)
    },
    destroyed () {
        this.$bus.$off('shopWasChanged', this.listen)
    },
}
</script>
